













import { View } from 'client-website-ts-library/plugins';
import { API, Config } from 'client-website-ts-library/services';
import { Office } from 'client-website-ts-library/types';
import { Component, Mixins } from 'vue-property-decorator';

@Component
export default class About extends Mixins(View) {
  private office: Office | null = null;

  mounted() {
    API.Offices.Get(Config.Website.Settings!.WebsiteId).then((office) => {
      this.office = office;
    });
  }
}
